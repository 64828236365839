import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { receiveProducts, receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';
import FilteredMasonry from './FilteredMasonry';

// wird nicht mehr gebraucht: Header soll ganz raus
// import MerchstandImages from './assets/images/MerchstandImages';

interface RouteParams {
  readonly id: string;
}

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
};

const Home: React.FC<Props> = ({ productFilter = (p) => p }) => {
  const { t } = useTranslation();
  const params = useParams<RouteParams>();
  const { products, loadingProducts, tickets, loadingTickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts, receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  if (!productFilter && params?.id) {
    productFilter = (p) => p.category_ids.includes(Number(params.id));
  }

  React.useEffect(() => {
    receiveProducts();
    receiveTickets();
  }, [language]);

  return (
    <article className="mb-5">
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="landing-page" />
      </Helmet>

      {/* <div className="list-page-highlights">
          <div className="container list-page">
            {loadingProducts || loadingTickets ? ( // if loading
              <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
            ) : products.length === 0 ? ( // if no products
              <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
            ) : (
              // else show products
              <FilteredMasonry
                id="product-listing"
                products={products.filter(productFilter)}
                filters="off"
                dontUpdateQueryStrings
                tagId={6183}
                showSubtitle
                columnClassName="col-12 col-md-6 col-lg-6"
                // itemClassName="random-rotation-object"
                // itemImageClassName="scribble-hover-overlay-object"
              />
            )}
          </div>
        </div> */}

      <div className="stage mb-1 mt-3">
        <div className="container">
          <Link to="/">
            <img src={require('./assets/images/header.png')} alt="Edwin Rosen Tickets" className="img-fluid" />
          </Link>
        </div>
      </div>

      <h1 className="mt-5 mb-5 text-center">TICKETS</h1>

      <Container className="listing-filter-buttons-container mb-5">
        <h5>FILTERN:</h5>
        <Row className="mb-2 d-flex justify-items-center justify-content-center listing-buttons-container">
          <Col className="mt-3 col-12 col-md-6">
            <Link to="/tickets?tagId=8636" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'WENN ALLE STRICKE REISSEN TOUR'}
            </Link>
          </Col>
          <Col className="mt-3 col-12 col-md-6">
            <Link to="/tickets?tagId=8629" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'FESTIVALS 2025'}
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="list-page-tickets">
        <ContentrArea id="before-ticket-listing" />

        {!loadingTickets && tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          !params.id && ( // but only when not filtered for product category
            <>
              <Masonry id="ticket-listing" products={tickets} type="list" showCity showLocation showTags />
            </>
          )
        )}
      </Container>

      <Container className="list-page mb-5">
        <h1 className="mt-5 mb-1 text-center">MERCHANDISE</h1>
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
          <body className="product-listing-page" />
        </Helmet>
        <Masonry
          id="product-listing"
          products={products}
          columnClassName="col-sm-12 col-md-6 col-lg-4"
          filters="off"
          showFormerPrice
          showSubtitle
        />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products } = state.Products;
  const loadingProducts = state.Products.loading;
  const { tickets } = state.Tickets;
  const loadingTickets = state.Tickets.loading;
  return {
    products,
    loadingProducts,
    tickets,
    loadingTickets,
  };
};

const mapDispatchToProps = {
  receiveProducts,
  receiveTickets,
};

export default React.memo(Home);
